import React from 'react';
import { Helmet } from 'react-helmet';
import './Documentation.css';

function Documentation() {

  return (
    <div className="page">
      <Helmet>
        <title>GCG API Generator</title>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-4121438967817264",
                enable_page_level_ads: true
              });
            </script>
          `,
        }}
      />
      <div className="documentation-container">
        <h2 className="welcome-heading">Documentation</h2>
        <p className="instruction">You can download the OpenAPI YAML example file from the link below:</p>
        <a href="/gcg-openapi-example.yml" download="gcg-openapi-example.yml" className="download-link">Download OpenAPI YAML</a>
        <h3 className="section-heading">Labels</h3>
        <ul className="labels-list">
          <li>
            <p className="label-description"><b>gcg-description</b>: This label is mandatory and should have the same name as the operationId.</p>
            <img src="/description.png" alt="description image" />
          </li>
          <li>
            <p className="label-description"><b>gcg-entity-varname</b>: Found at the parameter level in paginated operations. It helps to identify the real field name in the table and construct the query. For example, the parameter is called orderId but in the Order component it is called id.</p>
            <p className="label-description"><b>To have the application detect an operation as paginated, both the operationId and the gcg-description must contain the word "Paginated", such as GetPaginatedOrders.</b></p>
            <p className="label-description">
            If the paginated query contains dates for which a search is to be performed between them, for example, if a search is to be made for an order placed between two creation dates, it is necessary to call them startCreationDate and endCreationDate so that the application constructs the query between these two dates</p>
            <img src="/varname.png" alt="varname image" />
          </li>
          <li>
            <p className="label-description"><b>x-table-gcg</b>: Found at the schema component object level and indicates which schema component is a table. This label is used to create the necessary use cases, services, and repositories for CRUD operations.</p>
            <img src="/table.png" alt="table image" />
          </li>
          <li>
            <p className="label-description"><b>gcg-info</b>: Found at the component parameter level. It is used to inform the primary key of the table. In the example YAML, it indicates that the primary key of the Order table is the parameter "id".</p>
            <img src="/pk.png" alt="pk image" />
          </li>
          <li>
            <p className="label-description">The tags label should exist for each path. For example:</p>
            <pre className="code-block"><code>tags:
  - OrderService</code></pre>
            <p className="label-description">At the end of the document, all tags should be listed.</p>
            <img src="/tags.png" alt="tags image" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Documentation;
