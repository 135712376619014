import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Generate.css';

const GenerateProjectForm = ({ onClose }) => {
  const [projectName, setProjectName] = useState('');
  const [packageName, setPackageName] = useState('');
  const [databaseType, setDatabaseType] = useState('SQL');
  const [controllerType, setControllerType] = useState('INTERFACE');
  const [hexagonalType, setHexagonalType] = useState('MODULES');
  const [buildType, setBuildType] = useState('BUILDER');
  const [injectionType, setInjectionType] = useState('CONSTRUCTOR');
  const [importedFile, setImportedFile] = useState(null);
  const [fileImported, setFileImported] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [yamlErrors, setYamlErrors] = useState([]);

  const validateYamlFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('https://back-brl2.onrender.com/validate-yaml', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error al validar el archivo YAML: ${response.status}`);
      }

      const errors = await response.json();
      return errors;
    } catch (error) {
      console.error('Error al validar el archivo YAML:', error.message);
      setErrorMessage('The yml file contains errors, check it and try again');
      return null;
    }
  };

  const generateStructure = async () => {
    setLoading(true);

    setErrorMessage('');

    try {
      const response = await fetch('https://back-brl2.onrender.com/generate-structure', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          packageName,
          projectName,
          hexagonalType,
          mapperType: 'MAPSTRUCT',
          controllerType,
          dataBaseType: databaseType,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al generar la estructura: ${response.status}`);
      }

    } catch (error) {
      console.error('Error al generar la estructura:', error.message);
      setErrorMessage('We got an error generating a proyect structure. Please try again');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!projectName || !packageName) {
      alert('Please fill in the required fields: Project Name and Package Name.');
      return;
    }

    if (!fileImported) {
      alert('Import a file before generating and downloading.');
      return;
    }

    setLoading(true);
    setYamlErrors([]);

    // Validate YAML file
    const errors = await validateYamlFile(importedFile);

    if (errors && errors.length > 0) {
      setYamlErrors(errors);
      setLoading(false);
      return;
    }

    await generateStructure();

    const formData = new FormData();
    formData.append('file', importedFile);
    formData.append('packageName', packageName);
    formData.append('projectName', projectName);
    formData.append('dataBaseType', databaseType);
    formData.append('controllerType', controllerType);
    formData.append('hexagonalType', hexagonalType);
    formData.append('buildType', buildType);
    formData.append('injectionType', injectionType);
    formData.append('username', 'fffff');

    try {
      const response = await fetch('https://back-brl2.onrender.com/import-yaml', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = projectName + '.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } catch (error) {
      console.error('Error al realizar la solicitud:', error.message);
      setErrorMessage('The yml file contains errors, check it and try again');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImportedFile(file);
    setFileImported(true);
  };

  return (
    <div>
      <Helmet>
        <title>GCG API Generator</title>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-4121438967817264",
                enable_page_level_ads: true
              });
            </script>
          `,
        }}
      />

      <div className="instruction-container">
        <ul className="instruction-list">
          <li><b>Controller type</b>: "Interface" if you implement the classes autogenerated by an API, "Implement" if you only want the implementation.</li>
          <li><b>Hexagonal type</b>: "Modules" if you want the project to be generated by modules, "Packages" if you want the project generation by packages.</li>
          <li><b>Build type</b>: "Data" if you want the classes to be generated with @Data from Lombok, "Builder" if you want the classes to be generated with @Builder and "Both" for both.</li>
          <li><b>Injection type</b>: "Constructor" if you want the dependency injection in the classes to be generated with private final, "Autowired" if you want them to be injected with the @Autowired annotation.</li>
        </ul>
      </div>

      {yamlErrors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          <ul>
            {yamlErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

      <form className="mt-5 p-3 border rounded bg-light col-8 mx-auto d-flex flex-column align-items-end" onSubmit={handleSubmit}>
        <div className="row w-100">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="projectName" className="form-label">Project Name</label>
              <input
                type="text"
                className="form-control"
                id="projectName"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="packageName" className="form-label">Package Name</label>
              <input
                type="text"
                className="form-control"
                id="packageName"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row w-100">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="databaseType" className="form-label">Database Type</label>
              <select className="form-select" aria-label="Default select example" value={databaseType} onChange={(e) => setDatabaseType(e.target.value)}>
                <option value="SQL">Sql</option>
                <option value="MONGODB">MongoDb</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="controllerType" className="form-label">Controller Type</label>
              <select className="form-select" aria-label="Default select example" value={controllerType} onChange={(e) => setControllerType(e.target.value)}>
                <option value="INTERFACE">Interface</option>
                <option value="OTHER">Implement</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row w-100">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="hexagonalType" className="form-label">Hexagonal Type</label>
              <select className="form-select" aria-label="Default select example" value={hexagonalType} onChange={(e) => setHexagonalType(e.target.value)}>
                <option value="MODULES">Modules</option>
                <option value="PACKAGES">Packages</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="buildType" className="form-label">Build Type</label>
              <select className="form-select" aria-label="Default select example" value={buildType} onChange={(e) => setBuildType(e.target.value)}>
                <option value="BUILDER">Builder</option>
                <option value="DATA">Data</option>
                <option value="BOTH">Both</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row w-100">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="injectionType" className="form-label">Injection Type</label>
              <select className="form-select" aria-label="Default select example" value={injectionType} onChange={(e) => setInjectionType(e.target.value)}>
                <option value="CONSTRUCTOR">Constructor</option>
                <option value="AUTOWIRED">Autowired</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="importFile" className="form-label">Import File</label>
              <input
                type="file"
                className="form-control"
                id="importFile"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {loading ? (
            <Loader type="Oval" color="#ffffff" height={20} width={20} />
          ) : (
            'Generate and download'
          )}
        </button>
      </form>
    </div>
  );
};

export default GenerateProjectForm;
