import React from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className="home-container">
      <Helmet>
        <title>GCG API Generator</title>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script>
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-4121438967817264",
                enable_page_level_ads: true
              });
            </script>
          `,
        }}
      />
      <div className="premium-features">
        <h3>Features:</h3>
        <ul>
          <li>Automated generation of controllers, dtos, usecases, services, and repositories.</li>
          <li>Creation of use cases, services, and repositories tailored to your specifications.</li>
          <li>Mapper interface generation using MapStruct.</li>
          <li>Comprehensive SQL table creation with relationships, documents in MongoDB, and generation of necessary entities and project structure for hexagonal architecture (domain and infrastructure entities).</li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
