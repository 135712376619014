import React from 'react';
import './PrivacyPolicyPage.css';

function PrivacyPolicyPage() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>On this website, we respect your privacy and are committed to protecting it. Below, we explain how we handle the information we collect when you visit our site.</p>

      <h2>Data Collection</h2>
      <p>We do not collect personal data from users on this website.</p>

      <h2>Use of Cookies</h2>
      <p>We use Google AdSense cookies to provide personalized ads. These cookies may collect information to display relevant ads for you. You can manage your cookie preferences in your browser settings.</p>

      <h2>Google AdSense Policy</h2>
      <p>For more information on how Google uses data collected through AdSense, please refer to the <a href="https://policies.google.com/technologies/partner-sites">Google websites and apps privacy policy</a>.</p>

      <p>If you have any questions about our privacy policy, feel free to contact us.</p>
    </div>
  );
}

export default PrivacyPolicyPage;
