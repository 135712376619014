import React, { useState } from 'react';
import './Dynamic.css';
import Loader from 'react-loader-spinner';

const DynamicForm = ({ formIndex }) => {

  const [yamlErrors, setYamlErrors] = useState([]); // Suponiendo que tienes este estado
  const [errorMessage, setErrorMessage] = useState(''); // Suponiendo que tienes este estado
  const [projectName, setProjectName] = useState('');
  const [packageName, setPackageName] = useState('');
  const [databaseType, setDatabaseType] = useState('SQL');
  const [controllerType, setControllerType] = useState('INTERFACE');
  const [hexagonalType, setHexagonalType] = useState('MODULES');
  const [buildType, setBuildType] = useState('BUILDER');
  const [injectionType, setInjectionType] = useState('CONSTRUCTOR');
  const [loading, setLoading] = useState(false);

  const [paths, setPaths] = useState([
    {
      path: '',
      method: '',
      operationId: '',
      parameters: [{ name: '', type: '', custom: '', required : false, in: ''}],
      isOpen: true,
      responses: [{ code: '', content: '', type: '', customResponseBodyType: '' }],
      requestBodyType: '',
      customRequestBodyType: '',
      tag: '',
      isPaginated: false
    }
  ]);

  const [components, setComponents] = useState([
    { isOpen: true, name: '', properties: [{ name: '', type: '', isPk: '', customProperty: '' }], isTable: false }
  ]);
  const [showApiForm, setShowApiForm] = useState(false);
  const [showComponentsForm, setShowComponentsForm] = useState(true);
  const [showStructureForm, setShowStructureForm] = useState(false);

  const javaResponseDataTypes = [
    'String', 'Integer', 'Long', 'Float', 'Double', 'Boolean', 'Character', 'Byte', 'Short', 'BigDecimal', 'Custom'
  ];

  const handleAddPath = () => {
    const newPaths = [
      ...paths,
      {
        path: '',
        method: '',
        operationId: '',
        parameters: [{ name: '', type: '', custom: '', required : false, in: ''}],
        isOpen: true,
        responses: [{ code: '', content: '', type: '', customResponseBodyType: '' }],
        requestBodyType: '',
        customRequestBodyType: '',
        tag: '',
        isPaginated: false
      }
    ];
    setPaths(newPaths);
  };

  const handleRemovePath = (index) => {
    const newPaths = paths.filter((_, i) => i !== index);
    setPaths(newPaths);
  };

  const handleChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    let updatedPaths = [...paths];

    if (type === 'checkbox') {
      if (name === 'responseBodyIsList' && checked) {
        updatedPaths[index].responseBodyIsMap = false;
      } else if (name === 'responseBodyIsMap' && checked) {
        updatedPaths[index].responseBodyIsList = false;
      } else if (name === 'requestBodyIsList' && checked) {
        updatedPaths[index].requestBodyIsMap = false;
      } else if (name === 'requestBodyIsMap' && checked) {
        updatedPaths[index].requestBodyIsList = false;
      }
    }

    updatedPaths[index] = {
      ...updatedPaths[index],
      [name]: type === 'checkbox' ? checked : value
    };

    setPaths(updatedPaths);
  };

  const handleAddParameter = (pathIndex) => {
    const newPaths = paths.map((path, i) => {
      if (i === pathIndex) {
        return {
          ...path,
          parameters: [...path.parameters, { name: '', type: '', custom: '', required: false, in: ''}]
        };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleAddResponse = (pathIndex) => {
    const newPaths = paths.map((path, i) => {
      if (i === pathIndex) {
        return {
          ...path,
          responses: [...path.responses, { code: '', content: '', type: '', customResponseBodyType: '' }]
        };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleRemoveParameter = (pathIndex, paramIndex) => {
    const newPaths = paths.map((path, i) => {
      if (i === pathIndex) {
        return {
          ...path,
          parameters: path.parameters.filter((_, j) => j !== paramIndex)
        };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleParameterChange = (pathIndex, paramIndex, event) => {
      const { name, value, type, checked } = event.target;
      const newPaths = [...paths];
      newPaths[pathIndex].parameters[paramIndex][name] = type === 'checkbox' ? checked : value;
      setPaths(newPaths);
    };

  const handleResponseChange = (pathIndex, responseIndex, event) => {
    const { name, value, type, checked } = event.target;
    const newPaths = paths.map((path, i) => {
      if (i === pathIndex) {
        const newResponses = path.responses.map((response, j) => {
          if (j === responseIndex) {
            if (name === 'code' && response.code !== value) {
              setSelectedCodes((prevCodes) => {
                const newCodes = [...prevCodes];
                if (response.code) {
                  const codeIndex = newCodes.indexOf(response.code);
                  if (codeIndex !== -1) newCodes.splice(codeIndex, 1);
                }
                if (value) newCodes.push(value);
                return newCodes;
              });
            }
            return { ...response, [name]: type === 'checkbox' ? checked : value };
          }
          return response;
        });
        return { ...path, responses: newResponses };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleRemoveResponse = (pathIndex, responseIndex) => {
    const newPaths = paths.map((path, i) => {
      if (i === pathIndex) {
        const removedCode = path.responses[responseIndex].code;
        if (removedCode) {
          handleRemoveCode(removedCode);  // Eliminar el código de selectedCodes
        }
        return {
          ...path,
          responses: path.responses.filter((_, j) => j !== responseIndex)
        };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleToggleOpen = (index) => {
    const newPaths = paths.map((path, i) => {
      if (i === index) {
        return { ...path, isOpen: !path.isOpen };
      }
      return path;
    });
    setPaths(newPaths);
  };

  const handleToggleComponentOpen = (index) => {
      const newComponent = components.map((component, i) => {
        if (i === index) {
          return { ...component, isOpen: !component.isOpen };
        }
        return component;
      });
      setComponents(newComponent);
    };

  const [errors, setErrors] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = paths.map((path, index) => {
      const pathErrors = {};
      if (!path.path) pathErrors.path = true;
      if (!path.method) pathErrors.method = true;
      if (!path.operationId) pathErrors.operationId = true;
      if (!path.tag) pathErrors.tag = true;

      const parameterErrors = path.parameters.map(param => {
        const paramErrors = {};
        if (!param.name) paramErrors.name = true;
        if (!param.type) paramErrors.type = true;
        if (param.type === 'Custom' && !param.custom) paramErrors.custom = true;
        return paramErrors;
      });

      pathErrors.parameters = parameterErrors;

      const responseErrors = path.responses.map(response => {
        const respErrors = {};
        if (!response.code) respErrors.code = true;
        if (response.code === '200') {
          if (!response.content) respErrors.content = true;
          if (!response.type) respErrors.type = true;
          if (response.type === 'Custom' && !response.customResponseBodyType
            ) respErrors.customResponseBodyType = true;
        }
        return respErrors;
      });

      pathErrors.responses = responseErrors;

      if (path.requestBodyType === 'Custom' && !path.customRequestBodyType) {
        pathErrors.customRequestBodyType = true;
      }

      return pathErrors;
    });

    setErrors(newErrors);

    const hasErrors = newErrors.some(pathErrors =>
      pathErrors.path || pathErrors.method || pathErrors.operationId || pathErrors.tag ||
      pathErrors.parameters.some(paramErrors => Object.values(paramErrors).some(Boolean)) ||
      pathErrors.responses.some(respErrors => Object.values(respErrors).some(Boolean)) ||
      pathErrors.customRequestBodyType
    );

    if (!hasErrors) {
      console.log('Form send', paths);
      setShowApiForm(false);
      setShowStructureForm(true);
    }
  };

  const [selectedCodes, setSelectedCodes] = useState([]);

  const httpCodes = ['200', '201', '204', '400', '401', '403', '404', '500'];

  const handleRemoveCode = (codeToRemove) => {
    setSelectedCodes(selectedCodes.filter(code => code !== codeToRemove));
  };

  const initialPath = {
      path: '',
      method: '',
      operationId: '',
      tag: '',
      parameters: [{ name: '', type: '', custom: '', required : false, in: '' }],
      responses: [{ code: '', content: '', type: '', customResponseBodyType: '' }],
      requestBodyType: '',
      customRequestBodyType: '',
      isOpen: true,
    };

  const resetForm = () => {
      setPaths([initialPath]);
      setErrors([]);
    };
  const handleGoBack = () => {
    setShowComponentsForm(true);
      setShowApiForm(false);
  };

  const handleGoBackComponentsForm = () => {
      setShowApiForm(true);
        setShowStructureForm(false);
    };

  const handleSubmitStructure = (event) => {
        event.preventDefault();
        console.log(paths);
        console.log(components);
        console.log(projectName);
        console.log(databaseType);
        console.log(controllerType);
        console.log(buildType);
        console.log(hexagonalType);
        console.log(injectionType);
    };


    const handleChangeComponents = (componentIndex, e, propertyIndex = null) => {

      const { name, value } = e.target;
      const updatedComponents = [...components];
      if (propertyIndex !== null) {
        updatedComponents[componentIndex].properties[propertyIndex][name] = name === 'isPk' ? (value === 'true') : value;
      } else {
        updatedComponents[componentIndex][name] = name === 'isTable' ? (value === 'true') : value;

        // Si isTable se cambia a false, actualizar isPk a false en todas las propiedades
        if (name === 'isTable' && value === 'false') {
          updatedComponents[componentIndex].properties = updatedComponents[componentIndex].properties.map(property => ({
            ...property,
            isPk: false
          }));
        }
      }

      setComponents(updatedComponents);
    };

   //Devuelve un objeto comun
   const combinePathsAndComponents = (paths, components) => {
     return {
       paths: paths,
       components: components
     };
   };

   const handleComponentsSubmit = async (e) => {
     e.preventDefault();
     setShowComponentsForm(false);
     setShowApiForm(true);
   };

    const handleAddComponent = () => {
      setComponents([...components, { isOpen: true, name: '', properties: [{ name: '', type: '', isPk: '', customProperty: '' }], isTable: false }]);
    };

    const handleAddProperty = (componentIndex) => {
      const newComponents = [...components];
      newComponents[componentIndex].properties.push({ name: '', type: '' });
      setComponents(newComponents);
    };

    const handleRemoveProperty = (componentIndex, propertyIndex) => {
      const newComponents = [...components];
      newComponents[componentIndex].properties.splice(propertyIndex, 1);
      setComponents(newComponents);
    };

    const handleRemoveComponent = (componentIndex) => {
      const newComponents = [...components];
      newComponents.splice(componentIndex, 1);
      setComponents(newComponents);
    };

      const download = async (e) => {
          e.preventDefault();
          if (!projectName || !packageName) {
              alert('Please fill in the required fields: Project Name and Package Name.');
              return;
          }

          setLoading(true);
          setYamlErrors([]);

          try {
          //https://back-brl2.onrender.com
                const response = await fetch('https://back-brl2.onrender.com/generate-structure', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    packageName,
                    projectName,
                    hexagonalType,
                    mapperType: 'MAPSTRUCT',
                    controllerType,
                    dataBaseType: databaseType,
                  }),
                });

                if (!response.ok) {
                  throw new Error(`Error al generar la estructura: ${response.status}`);
                }

              } catch (error) {
                console.error('Error al generar la estructura:', error.message);
                setErrorMessage('We got an error generating a proyect structure. Please try again');
              } finally {
                setLoading(false);
              }

          const requestDTO = {
              packageName: packageName,
              projectName: projectName,
              dataBaseType: databaseType,
              controllerType: controllerType,
              hexagonalType: hexagonalType,
              buildType: buildType,
              injectionType: injectionType,
              paths: paths,
              components: components
          };

          try {
              const response = await fetch('https://back-brl2.onrender.com/generate-openapi', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(requestDTO)
              });

              if (!response.ok) {
                  throw new Error(`Error al realizar la solicitud: ${response.status}`);
              }

              const blob = await response.blob();
              const downloadUrl = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = projectName + '.zip';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

          } catch (error) {
              //console.error('Error al realizar la solicitud:', error.message);
              setErrorMessage(error.message);
              console.log(error);
          } finally {
              setLoading(false);
          }
      };


  return (
      <div className="scrollable-container">
        {showApiForm ? (
          <div className="form-container">
            <h3 style={{ marginTop: '10px', textAlign: 'center' }}>API INFORMATION</h3>
            <div className="button-container">
              <button type="submit" onClick={handleSubmit} className="submit-button" disabled={paths.length === 0}>
                Next
              </button>
              <button onClick={handleGoBack} className="submit-button">
                Back
              </button>
            </div>
            <form onSubmit={handleSubmit} className="dynamic-form">
              <div className="form-container">
                {paths.map((path, index) => (
                  <div key={`${formIndex}-${index}`} className={`path-container ${path.isOpen ? 'open' : 'closed'}`}>
                    <div className="path-header">
                      <button type="button" onClick={() => handleToggleOpen(index)} className="toggle-button">
                        {path.isOpen ? 'Collapse' : 'Expand'}
                      </button>
                      <button type="button" onClick={() => handleRemovePath(index)} className="remove-button">
                        Remove Path
                      </button>
                    </div>
                    <div className={`path-content ${path.isOpen ? 'visible' : 'hidden'}`}>
                      <input
                        type="text"
                        name="path"
                        placeholder="Path"
                        value={path.path}
                        onChange={(e) => handleChange(index, e)}
                        className={`input-field ${errors[index]?.path ? 'input-error' : ''}`}
                        required
                      />
                      <select
                        name="method"
                        value={path.method}
                        onChange={(e) => handleChange(index, e)}
                        className={`input-field ${errors[index]?.method ? 'input-error' : ''}`}
                      >
                        <option value="">Select Http Method</option>
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="DELETE">DELETE</option>
                        <option value="PATCH">PATCH</option>
                        <option value="OPTIONS">OPTIONS</option>
                        <option value="HEAD">HEAD</option>
                      </select>
                      <select
                        name="isPaginated"
                        value={path.isPaginated}
                        onChange={(e) => handleChange(index, e)}
                        className={`input-field ${errors[index]?.isPaginated ? 'input-error' : ''}`}
                      >
                        <option value={false}>Not paginated</option>
                        <option value={true}>It´s paginated</option>
                      </select>
                      {(path.method === 'POST' || path.method === 'PUT' || path.method === 'PATCH') && (
                        <div className="response-body-type-container">
                          <select
                            name="requestBodyType"
                            value={path.requestBodyType}
                            onChange={(e) => handleChange(index, e)}
                            className={`input-field ${errors[index]?.customRequestBodyType ? 'input-error' : ''}`}
                          >
                            <option value="">Request Body Type</option>
                            {javaResponseDataTypes.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                          {path.requestBodyType === 'Custom' && (
                            <input
                              type="text"
                              name="customRequestBodyType"
                              placeholder="Custom Name"
                              value={path.customRequestBodyType}
                              onChange={(e) => handleChange(index, e)}
                              className={`input-field ${errors[index]?.customRequestBodyType ? 'input-error' : ''}`}
                            />
                          )}
                        </div>
                      )}

                      <input
                        type="text"
                        name="operationId"
                        placeholder="Operation ID"
                        value={path.operationId}
                        onChange={(e) => handleChange(index, e)}
                        className={`input-field ${errors[index]?.operationId ? 'input-error' : ''}`}
                      />
                      <input
                        type="text"
                        name="tag"
                        placeholder="Tag"
                        value={path.tag}
                        onChange={(e) => handleChange(index, e)}
                        className={`input-field ${errors[index]?.tag ? 'input-error' : ''}`}
                      />
                      <div className="parameters">
                        <label className="parameter-label">Parameters:</label>
                        {path.parameters.map((param, paramIndex) => (
                          <div key={`${formIndex}-${index}-${paramIndex}`} className="parameter">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={param.name}
                              onChange={(e) => handleParameterChange(index, paramIndex, e)}
                              className={`input-field-param ${errors[index]?.parameters[paramIndex]?.name ? 'input-error' : ''}`}
                            />
                            <select
                              name="type"
                              value={param.type}
                              onChange={(e) => handleParameterChange(index, paramIndex, e)}
                              className={`input-field-param-select ${errors[index]?.parameters[paramIndex]?.type ? 'input-error' : ''}`}
                            >
                              <option value="">Select Type</option>
                              <option value="String">String</option>
                              <option value="Integer">Integer</option>
                              <option value="Long">Long</option>
                              <option value="Float">Float</option>
                              <option value="Double">Double</option>
                              <option value="Boolean">Boolean</option>
                              <option value="Character">Character</option>
                              <option value="Custom">Custom</option>
                            </select>
                            <select
                              name="in"
                              value={param.in}
                              onChange={(e) => handleParameterChange(index, paramIndex, e)}
                              className={`input-field-param-select ${errors[index]?.parameters[paramIndex]?.in ? 'input-error' : ''}`}
                            >
                              <option value="query">In query</option>
                              <option value="header">In header</option>
                              <option value="path">In path</option>
                              <option value="cookie">In cookie</option>
                            </select>
                            <select
                              name="required"
                              value={param.required}
                              onChange={(e) => handleParameterChange(index, paramIndex, e)}
                              className={`input-field-param-select ${errors[index]?.parameters[paramIndex]?.required ? 'input-error' : ''}`}
                            >
                              <option value="true">Required</option>
                              <option value="false">Not Required</option>
                            </select>
                            {param.type === 'Custom' && (
                              <input
                                type="text"
                                name="custom"
                                placeholder="Custom"
                                value={param.custom}
                                onChange={(e) => handleParameterChange(index, paramIndex, e)}
                                className={`input-field-param-custom ${errors[index]?.parameters[paramIndex]?.custom ? 'input-error' : ''}`}
                              />
                            )}
                            <button
                              type="button"
                              onClick={() => handleRemoveParameter(index, paramIndex)}
                              className={param.type !== 'Custom' ? 'remove-button-param' : 'remove-button-param2'}
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <button type="button" onClick={() => handleAddParameter(index)} className="add-button">
                          Add Parameter
                        </button>
                      </div>
                      <label className="response-label">Responses:</label>
                      {path.responses.map((response, responseIndex) => (
                        <div key={`${formIndex}-${index}-${responseIndex}`} className="response-item">
                          <select
                            name="code"
                            value={response.code}
                            onChange={(e) => handleResponseChange(index, responseIndex, e)}
                            className={`input-field ${errors[index]?.responses[responseIndex]?.code ? 'input-error' : ''}`}
                          >
                            <option value="">Select Code</option>
                            {httpCodes.map((code) => (
                              <option key={code} value={code}>
                                {code}
                              </option>
                            ))}
                          </select>

                          {response.code !== '204' && response.code !== '' && (
                            <div className="response-body-type-container">
                              <select
                                name="content"
                                value={response.content}
                                onChange={(e) => handleResponseChange(index, responseIndex, e)}
                                className={`input-field ${errors[index]?.responses[responseIndex]?.content ? 'input-error' : ''}`}
                              >
                                <option value="">Select Content Type</option>
                                <option value="application/json">application/json</option>
                                <option value="multipart/form-data">multipart/form-data</option>
                              </select>
                              <select
                                name="type"
                                value={response.type}
                                onChange={(e) => handleResponseChange(index, responseIndex, e)}
                                className={`input-field ${errors[index]?.responses[responseIndex]?.type ? 'input-error' : ''}`}
                              >
                                <option value="">Response Body Type</option>
                                {javaResponseDataTypes.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          {response.type === 'Custom' && (
                            <input
                              type="text"
                              name="customResponseBodyType"
                              placeholder="Custom Class Name"
                              value={response.customResponseBodyType || ''}
                              onChange={(e) => handleResponseChange(index, responseIndex, e)}
                              className={`input-field ${errors[index]?.responses[responseIndex]?.customResponseBodyType ? 'input-error' : ''}`}
                            />
                          )}
                          <button
                            type="button"
                            onClick={() => handleRemoveResponse(index, responseIndex)}
                            className="remove-path-button"
                          >
                            Remove Response
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={() => handleAddResponse(index)} className="add-button">
                        Add Response
                      </button>
                    </div>
                  </div>
                ))}
                <button type="button" onClick={handleAddPath} className="add-button">
                  Add Path
                </button>
              </div>
            </form>
          </div>
        ) : null}
        {showComponentsForm && (
          <div className="form-container">
            <h3 style={{ marginTop: '10px', textAlign: 'center' }}>SCHEMA COMPONENTS</h3>
            <div className="button-container">
              <button type="submit" onClick={handleComponentsSubmit} className="submit-button">
                Send
              </button>
            </div>
            <form onSubmit={handleComponentsSubmit} className="dynamic-form">
              <div className="form-container">
                {components.map((component, componentIndex) => (
                  <div key={`component-${componentIndex}`} className={`path-container ${component.isOpen ? 'open' : 'closed'}`}>
                    <div className="path-header">
                      <button type="button" onClick={() => handleToggleComponentOpen(componentIndex)} className="toggle-button">
                        {component.isOpen ? 'Collapse' : 'Expand'}
                      </button>
                      <button type="button" onClick={() => handleRemoveComponent(componentIndex)} className="remove-property-button">
                        Remove Component
                      </button>
                    </div>
                    <div className={`parameter-label ${component.isOpen ? 'visible' : 'hidden'}`}>
                      <input
                        type="text"
                        name="name"
                        placeholder="Component Name"
                        value={component.name}
                        onChange={(e) => handleChangeComponents(componentIndex, e)}
                        className={`input-field-component-name ${errors[componentIndex]?.name ? 'input-error' : ''}`}
                        required
                      />
                      &nbsp;Is table:
                      <select
                        name="isTable"
                        value={component.isTable}
                        onChange={(e) => handleChangeComponents(componentIndex, e)}
                        className={`input-field-property-select ${errors[componentIndex]?.parameters[componentIndex]?.isTable ? 'input-error' : ''}`}
                      >
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                      </select>
                      {component.properties.map((property, propertyIndex) => (
                        <div key={`${componentIndex}-${propertyIndex}`} className={`parameter-label ${component.isOpen ? 'visible' : 'hidden'}`}>
                          <input
                            key={propertyIndex}
                            type="text"
                            name="name"
                            placeholder="Property Name"
                            value={property.name}
                            onChange={(e) => handleChangeComponents(componentIndex, e, propertyIndex)}
                            className={`input-field-property-input ${errors[componentIndex]?.parameters[componentIndex]?.name ? 'input-error' : ''}`}
                            required
                          />
                          <select
                            name="type"
                            value={property.type}
                            onChange={(e) => handleChangeComponents(componentIndex, e, propertyIndex)}
                            className={`input-field-property-select ${errors[componentIndex]?.parameters[componentIndex]?.type ? 'input-error' : ''}`}
                          >
                            <option value="">Select Type</option>
                            <option value="String">String</option>
                            <option value="Integer">Integer</option>
                            <option value="Long">Long</option>
                            <option value="Float">Float</option>
                            <option value="Double">Double</option>
                            <option value="Boolean">Boolean</option>
                            <option value="Character">Character</option>
                            <option value="Custom">Custom</option>
                          </select>
                          {component.isTable === true && (
                            <>
                              Primary key:
                              <select
                                name="isPk"
                                value={property.isPk}
                                onChange={(e) => handleChangeComponents(componentIndex, e, propertyIndex)}
                                className={`input-field-property-select ${errors[componentIndex]?.parameters[componentIndex]?.isPk ? 'input-error' : ''}`}
                              >
                                <option value={false}>false</option>
                                <option value={true}>true</option>
                              </select>
                            </>
                          )}
                          {property.type === 'Custom' && (
                            <input
                              type="text"
                              name="customProperty"
                              placeholder="Custom"
                              value={property.custom}
                              onChange={(e) => handleChangeComponents(componentIndex, e, propertyIndex)}
                              className={`input-field-property-select ${errors[componentIndex]?.parameters[componentIndex]?.custom ? 'input-error' : ''}`}
                            />
                          )}
                          <button
                            type="button"
                            onClick={() => handleRemoveProperty(componentIndex, propertyIndex)}
                            className="remove-property-button"
                          >
                            Remove Property
                          </button>
                        </div>
                      ))}
                      <button type="button" onClick={() => handleAddProperty(componentIndex)} className="add-property-button">
                        Add Property
                      </button>
                    </div>
                  </div>
                ))}
                <button type="button" onClick={handleAddComponent} className="add-button">
                  Add Component
                </button>
              </div>
            </form>
          </div>
        )}
        {showStructureForm && (
          <div className="form-container">
            <h3 style={{ marginTop: '10px', textAlign: 'center' }}> PROJECT STRUCTURE</h3>
            <div className="button-container">
              <button onClick={handleGoBackComponentsForm} className="submit-button">
                Back
              </button>
              <button type="submit" onClick={download} className="submit-button">
                {loading ? (
                <Loader type="Oval" color="#ffffff" height={20} width={20} />
                ) : (
                  'Generate'
                )}
              </button>
            </div>
            <form className="mt-5 p-3 border rounded bg-light col-8 mx-auto d-flex flex-column align-items-end" onSubmit={handleSubmit}>
            <div className="row w-100">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="projectName" className="form-label">Project Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="projectName"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="packageName" className="form-label">Package Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="packageName"
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="databaseType" className="form-label">Database Type</label>
                  <select className="form-select" aria-label="Default select example" value={databaseType} onChange={(e) => setDatabaseType(e.target.value)}>
                    <option value="SQL">Sql</option>
                    <option value="MONGODB">MongoDb</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="controllerType" className="form-label">Controller Type</label>
                  <select className="form-select" aria-label="Default select example" value={controllerType} onChange={(e) => setControllerType(e.target.value)}>
                    <option value="INTERFACE">Interface</option>
                    <option value="OTHER">Implement</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="hexagonalType" className="form-label">Hexagonal Type</label>
                  <select className="form-select" aria-label="Default select example" value={hexagonalType} onChange={(e) => setHexagonalType(e.target.value)}>
                    <option value="MODULES">Modules</option>
                    <option value="PACKAGES">Packages</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="buildType" className="form-label">Build Type</label>
                  <select className="form-select" aria-label="Default select example" value={buildType} onChange={(e) => setBuildType(e.target.value)}>
                    <option value="BUILDER">Builder</option>
                    <option value="DATA">Data</option>
                    <option value="BOTH">Both</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="injectionType" className="form-label">Injection Type</label>
                  <select className="form-select" aria-label="Default select example" value={injectionType} onChange={(e) => setInjectionType(e.target.value)}>
                    <option value="CONSTRUCTOR">Constructor</option>
                    <option value="AUTOWIRED">Autowired</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          </div>
        )}
      </div>
    );
  };

export default DynamicForm;
