import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Documentation from './components/documentation/Documentation';
import Login from './components/login/Login';
import Home from './components/home/Home';
import Generate from './components/generate/Generate';
import Privacy from './components/privacy/PrivacyPolicyPage';
import DynamicForm from './components/dynamic_form/DynamicForm';


function App() {
  // Estados para rastrear si los botones deben estar habilitados o no
  const [isLoginEnabled, setLoginEnabled] = useState(false);
  const [isSignUpEnabled, setSignUpEnabled] = useState(false);

  // Lógica para actualizar el estado según tus necesidades
  // Por ejemplo, puedes usar useEffect para validar los campos del formulario.

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-container">
            <div className="center-link">
              <Link to="/">
                <span role="img" aria-label="Home">
                  🏠 Home
                </span>
              </Link>
              <Link to="/dynamicForm">
                <span role="img" aria-label="Create yaml">
                    Create yaml
                </span>
              </Link>
            </div>
          </div>
        </header>
        <Routes>
          //<Route path="/documentation" element={<Documentation />} />
          //<Route path="/generate" element={<Generate />} />
          <Route path="/dynamicForm" element={<DynamicForm />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
